<template>
  <div v-if="enableCheckbox">
    <input
      id="form__complianceHeureka"
      v-model="complianceHeureka"
      class="form-check-input"
      name="complianceHeureka"
      type="checkbox">
    <label
      class="form-check-label"
      for="form__complianceHeureka"
      v-html="$options.filters.prepositions($t('globals.checkboxes.complianceHeureka'))"/>
  </div>
</template>

<script>
export default {
  computed: {
    complianceHeureka: {
      get() {
        return this.$store.state.cart.input.complianceHeureka
      },
      set(value) {
        this.$store.commit('cart/SET_CART_INPUT_FIELD', { field: 'complianceHeureka', value: value })
      },
    },
    enableCheckbox() {
      return (
        this.$contentSettings.events.heureka.shopPurchase.enable &&
        !this.$themeSettings.components.PageCheckoutInfo.disableHeurekaCheckbox
      )
    },
  },
}
</script>
